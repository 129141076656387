@use '@/styles/utils/mixins.scss' as *;

.megaMenuWrapper {
  min-height: 24.875rem;
  border-top: 0.063rem solid $primary;
  background-color: $neutral01;
  margin-top: 1.5rem;
  display: flex;
  font-family: 'Fakt Pro', sans-serif;

  .megaMenuContainer {
    width: 100%;
  }
  .leftMenuContent {
    flex: 1 1 auto;
    padding: 3rem 3.75rem;
    display: flex;
    gap: 2rem;
  }
  .menuListColumn {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      list-style: none;
      margin: 0;
      padding: 0;
      a {
        font-size: 1rem;
        color: $dark;
        &:hover,
        &:focus,
        &:active {
          color: $primaryAlt;
        }
      }
      .seeAllLink {
        color: $primary;
        font-family: 'Fakt Pro', sans-serif;
        font-size: 1rem;
        font-weight: 500;
        background-color: transparent;
        outline: 0;
        border: 0;
        cursor: pointer;
        &:hover {
          color: $primaryAlt;
        }
      }
    }
  }

  .menuListLink {
    font-size: 1rem;
    font-weight: bold;
    color: $dark;
    border-bottom: 0.063rem solid $primaryAlt;
    width: fit-content;
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
  .subMenu {
    display: flex;
    flex-direction: column;
  }
  .quickLinkContent {
    background-color: $primary;
    color: $white;
    width: 23.625rem;
    padding: 3rem 3.75rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    height: 100%;
  }
  .quickLinkTitle {
    font-size: 1rem;
    font-weight: 500;
  }
  .quickLinkList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    a {
      font-size: 1rem;
      color: $white;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .quickLinkDivider {
    border-color: $primaryAlt !important;
    width: 100%;
    margin: 0 !important;
  }
  .quickLinkfooter {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 1rem;
    p {
      margin-bottom: 0;
    }
    a {
      font-size: 1rem;
      color: $white;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
}

.menuLink {
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  color: $dark;
  position: relative;
  font-family: 'Fakt Pro', sans-serif;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.063rem;
    width: 0;
    background-color: $primary;
    transition: width 0.3s;
  }
  &:hover::before,
  &:focus::before,
  &:active::before,
  &Active::before {
    width: 100%;
    transition: width 0.3s;
  }
  &:active,
  &:focus,
  &:hover,
  &Active {
    color: $primary;
  }
}
